import React, { useContext, useEffect, useState } from 'react';
import { SnackContext } from '../../contexts/Snacks';
import { ConfirmContext } from '../../contexts/Confirm';
import { FetchContext } from '../../contexts/FetchContext';
import { useRouter } from 'next/router';
import { fetchWrapperFormData, fetchWrapperJson } from './fetchWrapper'
import { addUrlParameters } from '../urlUtils';


const useFetch = () => {
  const { numberOfRequests, setNumberOfRequests, isLoading } = useContext(FetchContext);
  const { sendSnack } = useContext(SnackContext);
  const { confirmModal } = useContext(ConfirmContext);
  const router = useRouter();

  const buildCustomFetch = (method, fetchWrapper) => {
    const customFetch = async (path, body, config) => {
      const url = `${path}`;
      const isSnackingEnabled = config && (method != 'GET' || config.isSnackingEnabled === true);
      const hasConfirm = config && config.confirmTitle

      if(hasConfirm){
        if(!await confirmModal(config.confirmTitle, config.confirmMessage)){
          return {}
        }
      }

      if (!config?.skipLoader && setNumberOfRequests) {
        setNumberOfRequests(r => r + 1)
      }
      if (config?.setIsLoading) config?.setIsLoading(true);
      try {
        console.log({fetchWrapper})
        const response = await fetchWrapper(url, method, body)

        let data
        try{
          data = await response.json();
        }catch{
          data = {}
        }
        console.log({isSnackingEnabled})
        if (!response.ok) {
          if (isSnackingEnabled) sendSnack(data.message || config.errorMessage || 'An error occurred!', 'error');

          console.error('useFetch', 'failed with', data);
          throw new Error(`Error fetching data ${url} ${JSON.stringify(data)}`);
        } else {
          if (isSnackingEnabled) {
            if (data.success === false) {
              sendSnack(data.message || config.errorMessage || 'An error occurred!', 'error');
            } else {
              sendSnack(config.successMessage, config.successSeverity || 'success');
            }
          }
          if (data.success && data.redirectUrl) {
            if(data.redirectUrl == window.location.pathname){
              router.reload()
            }else{
              router.push(data.redirectUrl);
            }
          }
          if (data.updatedState && config?.updateState) {
            config.updateState(data.updatedState);
          }

          console.log('useFetch response', data);
          return data;
        }
      } catch (e) {
        console.error('useFetch error', e);
        throw e;
      } finally {
        if (!config?.skipLoader && setNumberOfRequests) {
          setNumberOfRequests(r => r - 1)
        }
        if (config?.setIsLoading) config?.setIsLoading(false);
      }
    };
    return customFetch;
  };

  const doGetInternal = buildCustomFetch('GET', fetchWrapperJson);
  const doGet = (path, parameters, config) => doGetInternal(addUrlParameters(path, parameters), undefined, config);
  const doPost = buildCustomFetch('POST', fetchWrapperJson);
  const doPut = buildCustomFetch('PUT', fetchWrapperJson);
  const doDelete = buildCustomFetch('DELETE', fetchWrapperJson);
  const doPostFormData = buildCustomFetch('POST', fetchWrapperFormData);
  
  const fetchImpl = {
    doGet,
    doPost,
    doPut,
    doDelete,
    doPostFormData,
    get: doGet,
    post: doPost,
    put: doPut,
    delete: doDelete,
    postForm: doPostFormData,
    isLoading,
  };

  // sync impl to window for dev useful dev tools
  useEffect(() => {
    window.DERP = window.DERP || {}
    window.DERP = {
      ...window.DERP,
      ...fetchImpl
    }

  }, [isLoading])

  return fetchImpl;
};

export default useFetch